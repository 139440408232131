import { buscarHoldings, buscarClientes } from '../../../produto/common/resources/planejamento/planejamento-acao-cadastro';

export default {
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      anoFiscalResource: this.$api.anoFiscal(this.$resource),
      extensaoResource: this.$api.extensao(this.$resource),
      tipoInvestimentoResource: this.$api.tipoInvestimento(this.$resource),
      centroCustoResource: this.$api.centroCusto(this.$resource),
      clienteResource: this.$api.cliente(this.$resource),
      produtoResource: this.$api.produto(this.$resource),
      tipoAcaoResource: this.$api.tipoAcao(this.$resource),
      orcamentoTipoVerbaResource: this.$api.orcamentoTipoVerba(this.$resource),
      tipoBeneficioResource: this.$api.tipoBeneficio(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),

      camposFormulario: {
        padrao: [

          {
            labelCampo: 'data_inicio',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'data_fim',
            tipoCampo: 'DATA',
          },
          {
            labelCampo: 'holding',
            nomCampoId: 'id_holding',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            filter: () => true,
            async: {
              fetchFunction: (autocomplete) => buscarHoldings({ autocomplete }, this.$resource),
              itemValue: 'id',
              itemText: 'nomExtensao',
            },
          },
          {
            labelCampo: 'cliente',
            nomCampoId: 'id_cliente',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                };
                return buscarClientes(parametros, this.$resource);
              },
              itemValue: 'id',
              itemText: 'codNome',
            },
          },
        ],
      },
    };
  },
};
