<template>
  <v-card style="margin-bottom:1em" class="PlanejamentoAcaoFiltros">
    <v-card-title>
      <span class="title">{{ $tc('title.filtro', 2) }}</span>
      <v-spacer></v-spacer>
      <v-icon v-show="filtrosAbertos"
        @click="fixaFiltros"
        :class="filtrosFixosClass">
        fa-thumbtack
      </v-icon>

      <icon-filtro @click="toggleFiltros" :filtros="{...filtrosPlanejamentoAcao}" :min="1" />
    </v-card-title>

    <v-container fluid grid-list-md v-show="filtrosAbertos">
      <metadados-container-layout
        :ordenacao-campos="ordenacaoCampos"
        :campos-formulario="camposFormulario"
        :formulario-filtros="true"
        :ignora-obrigatorios="false"
        :input-layout="inputLayout"
        :objeto="filtrosPlanejamentoAcao"
        ref="container">
      </metadados-container-layout>
    </v-container>
    <v-card-actions v-show="filtrosAbertos">
      <v-spacer></v-spacer>
      <v-btn @click="resetaFiltros"
        color="accent"
      >{{$t('label.limpar_filtros')}}</v-btn>
      <v-btn @click="aplicarFiltros"
        color="primary"
      >{{$t('label.filtrar')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import {
  skipLoading,
} from '@/produto/common/functions/loading';
import { copyObject } from '../../../produto/common/functions/helpers';

import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import PlanejamentoAcaoMassivoFiltrosCampos from './PlanejamentoAcaoMassivoFiltrosCampos';
import IconFiltro from '../../../produto/shared-components/filtros/IconFiltro';

export default {
  name: 'PlanejamentoAcaoFiltros',
  mixins: [
    PlanejamentoAcaoMassivoFiltrosCampos,
  ],
  components: {
    MetadadosContainerLayout,
    IconFiltro,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),

      filtrosAbertos: false,
      carregouCampos: false,

      metadadosPlanejamentoAcao: null,

      ordenacaoCampos: [
        'cliente',
        'holding',
        'data_inicio',
        'data_fim',
      ],

      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },

      filtrosPlanejamentoAcao: {},
    };
  },
  methods: {
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
    },
    resetaFiltros() {
      this.filtrosPlanejamentoAcao = {};
      this.$refs.container.refresh();
      setTimeout(() => this.aplicarFiltros());
    },
    restaurarFiltros() {
      const parametros = {
        ...this.filtrosPlanejamentoAcao,
      };
      const idsClientes = [];
      const idsHoldings = [];

      if (parametros.cliente !== undefined) {
        parametros.cliente.forEach((c) => {
          idsClientes.push(c.id);
        });
      }
      if (parametros.holding !== undefined) {
        parametros.holding.forEach((h) => {
          idsHoldings.push(h.id);
        });
      }
      const filtros = {
        id_cliente: idsClientes,
        id_holding: idsHoldings,
        ...parametros,
      };
      skipLoading();
      this.$emit('PlanejamentoAcaoFiltros__AplicaFiltros', filtros);
    },
    aplicarFiltros() {
      this.restaurarFiltros();
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosPlanejamentoAcao;
      this.workspace.filtros.tipoFiltro = this.tipoFiltro;

      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    preencheHoldings() {
      this.configuracaoResource
        .buscarHoldingsCliente()
        .then((res) => {
          if (this.filtrosPlanejamentoAcao.holding === null
                || this.filtrosPlanejamentoAcao.holding === undefined) {
            this.filtrosPlanejamentoAcao.holding = res.body;
            this.$refs.container.refresh();
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    const metadados = this.getAcaoMetadado;
    this.metadadosPlanejamentoAcao = copyObject(metadados);
  },
};
</script>
<style>
  .PlanejamentoAcoesFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .PlanejamentoAcoesFiltros__Opcoes div.v-input__slot,
  .PlanejamentoAcoesFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .PlanejamentoAcoesFiltros__Opcoes div.v-messages {
    display: none;
  }
  .PlanejamentoAcoesFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
