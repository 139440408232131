<template>
  <div>
    <div v-if="showLoading">
      <p style="text-align: center; margin-top:15px">
        {{ $tc('message.aguarde_carregamento', 1) }}
      </p>
    </div>
    <v-data-table
      :headers="cabecalhoPorTipoAcao()"
      v-if="!showLoading"
      :items="tipoAcao.acoes"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
      }">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.nome">
            <td style="width: 2%; text-align: center;">
              <v-menu bottom
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    :id="'more_vert_' + item.id"
                    icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="acaoEmCadastro(item) && canEdit"
                    @click="editarAcao(item.id_trade_previa)">
                    <v-list-item-action>
                      <v-icon>edit</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.editar', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="validaConcluir(item)">
                    <v-list-item-action>
                      <v-icon>thumb_up</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.concluir2', 1) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="mostrarBotaoCancelarAcao(item)"
                    @click="confirmarCancelarAcao(item)">
                    <v-list-item-action>
                      <v-icon>cancel</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.excluir', 1) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td v-for="col in colunasPorTipoAcao()" :key="col">
              <status
                v-if="col === 'status'"
                :status="item.status"
                :tooltip="item.status">
              </status>
              <span v-else>
                {{formataValorColuna(col, item)}}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
      :id-acao="acaoSelecionada.id"
    >
    </planejamento-acao-justificativa>

    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>
    <confirm
      ref="concluirAcaoSemFluxoDialog"
      :max-width="'500'"
      :message="$t('message.concluir_acao_sem_fluxo_massivo')"
      :persistent="true"
      @agree="concluirAcao"/>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import { buscarAcoes } from '@/common/resources/suzano-planejamento-acao-listagem-massiva';
import { generateComputed } from '../../../produto/common/functions/roles-computed-generator';
import { getMoney, getPercent } from '../../../produto/common/functions/helpers';
import Status from '../../../produto/shared-components/Status';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';
import { skipLoading } from '../../../produto/common/functions/loading';

export default {
  components: {
    Status,
    Confirm,
    PlanejamentoAcaoJustificativa,
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...generateComputed('ACAO', [
      'canEdit',
    ]),
    metadadosAcao() {
      if (this.getAcaoMetadado && this.getAcaoMetadado.mapaEntidades) {
        return this.getAcaoMetadado;
      }
      return undefined;
    },
  },
  props: {
    tipoAcao: Object,
    ordenacao: Array,
    filtros: Object,
    agrupamento: String,
    labelCampoCliente: {
      type: String,
      default: '',
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.mesmaPagina(this.pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = this.pagination;
        this.buscarAcoes();
      },
      deep: true,
    },
  },
  data() {
    return {
      orcamentoResource: this.$api.orcamento(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoAcaoMassivoResource: this.$api.suzanoPlanejamentoAcaoListagemMassiva(this.$resource),
      totalPage: 0,
      pagination: {},
      lastPagination: {},
      acoesAprovacao: null,
      showLoading: false,
      cabecalhoFixo: [
        {
          text: this.$tc('label.status', 1), value: 'status', sortable: false, width: '5%',
        },
        {
          text: this.$tc('label.data_inicio', 1),
          value: 'data_inicio',
          sortable: false,
          width: '10%',
        },
        {
          text: this.$tc('label.data_fim', 1), value: 'data_fim', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.cliente_customer', 1), value: 'cliente_customer', sortable: false, width: '10%',
        },
        {
          text: '', value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
      ],
      pesquisaValida: true,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      acaoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      statusPassos: {},
      acaoMassivaEditar: null,
      acaoConcluir: {
        type: Function,
        default: () => ({}),
      },
    };
  },
  methods: {
    validaConcluir(item) {
      this.acoesAprovacao = null;
      this.acoesAprovacao = item;
      this.$refs.concluirAcaoSemFluxoDialog.open();
    },
    getMoney,
    getPercent,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    validarDatas(filtros) {
      const dataInicial = moment(filtros.data_inicio);
      const dataFinal = moment(filtros.data_fim);

      if (filtros.data_inicio !== undefined && filtros.data_fim === undefined) {
        this.$toast(this.$t('errors.dta_fim.obrigatoria'));
        return false;
      }

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }
      return true;
    },
    buscarAcoes(filtros) {
      if (filtros !== null && filtros !== undefined) {
        this.pesquisaValida = this.validarDatas(filtros);
      }
      if (this.pesquisaValida) {
        const params = {
          ...filtros,
          numeroPagina: this.pagination.page,
          tamanhoPagina: this.pagination.itemsPerPage,
        };

        params.id_acao_tipo = this.tipoAcao.id;
        if (this.tipoAcao.idAgrupamento) {
          params.id_agrupamento = this.tipoAcao.idAgrupamento;
          params.agrupamento = this.agrupamento;
        }
        skipLoading();
        this.showLoading = true;
        buscarAcoes(params, this.$resource)
          .then((res) => {
            this.totalPage = res.data.quantidadeRegistrosPagina;
            this.tipoAcao.acoes = [...res.data.resposta];
            setTimeout(() => this.$forceUpdate());
            this.showLoading = false;
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
    acaoAprovada(acao) {
      const { status } = acao;
      return status === 'APROVADO';
    },
    acaoEmCadastro(acao) {
      const { status } = acao;
      return status === 'EM_CADASTRO';
    },
    acaoCancelada(acao) {
      const { status } = acao;
      return status === 'CANCELADO';
    },
    editarAcao(idAcao) {
      this.$router.push({ name: 'editarAcaoMassiva', params: { idAcao, from: 'planejamento-acao-massivo' } });
    },
    cabecalhoPorTipoAcao() {
      const { tipoAcao } = this;
      if (tipoAcao.cabecalho) return tipoAcao.cabecalho;

      const ordenacao = this.colunasPorTipoAcao(),
        cabecalho = [];

      ordenacao.forEach((col) => {
        let isColFixa = false;
        for (let i = 0; i < this.cabecalhoFixo.length; i += 1) {
          const defCol = this.cabecalhoFixo[i];
          isColFixa = defCol.value === col;
          if (isColFixa) {
            if (defCol.value === 'campo_cliente') {
              defCol.text = `${this.$tc('label.foco_acao', 1)} - ${this.labelCampoCliente}`;
            }
            cabecalho.push(defCol);
            break;
          }
        }
        if (!isColFixa) {
          cabecalho.push({
            text: this.tituloCampoDinamico(col),
            value: col,
            sortable: false,
          });
        }
      }, this);

      const colAcoes = this.cabecalhoFixo.filter((c) => c.value === 'acoes')[0];
      tipoAcao.cabecalho = [colAcoes, ...cabecalho];

      return tipoAcao.cabecalho;
    },
    colunasPorTipoAcao() {
      return ['cliente_customer', 'data_inicio', 'data_fim', 'status'];
    },
    tituloCampoDinamico(nomeCampo) {
      const dependencia = this.getDepenciaMetadadosPorNome(nomeCampo);
      if (dependencia != null) {
        return dependencia.entidadeEstrangeira;
      }
      const campoDinamico = this.getCampoDinamicoPorNome(nomeCampo);
      if (!campoDinamico) {
        $logger.log(nomeCampo);
      }
      return campoDinamico.nomCampo;
    },
    formataValorColuna(nomeColuna, item) {
      return item[nomeColuna];
    },
    getDepenciaMetadadosPorNome(nomeCampo) {
      const dependencias = this.metadadosAcao.mapaEntidades,
        dependencia = dependencias[nomeCampo];
      return dependencia;
    },
    getCampoDinamicoPorNome(nomeCampo) {
      const campos = this.metadadosAcao.mapaCamposDinamicos,
        campo = campos[nomeCampo];
      return campo;
    },
    abrirJustificativaAprovar(item) {
      const justObrigatoria = false;
      this.abrirJustificativaAvaliar(item, this.aprovar, justObrigatoria);
    },
    aprovar(justificativa) {
      const idAcao = this.acaoSelecionada.id;
      this.workflowAcaoResource.aprovarPasso({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.buscarStatusFluxo(this.acaoSelecionada, true, () => {
            if (this.acaoSelecionada.fluxo.statusFluxo === 'APROVADO') {
              this.acaoSelecionada.status = 'APROVADO';
              this.$forceUpdate();
            }
          });
          this.acaoSelecionada.aprovadorFluxo = false;
          this.$forceUpdate();
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    confirmarCancelarAcao(acao) {
      acao.ids_acao = JSON.parse(acao.ids_acao);
      const content = this.$t('message.deseja_excluir_acao');
      setTimeout(() => this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          content,
          (ok) => {
            if (ok) {
              acao.ids_acao.forEach((a) => {
                this.cancelarAcao(a);
              });
            }
          },
        ));
    },
    cancelarAcao(acao) {
      const idAcao = acao;
      this.planejamentoAcaoResource.cancelarAcao({ idAcao }, { idAcao })
        .then(() => {
          this.buscarAcoes();
          this.$toast(this.$t('message.acao_massiva_excluida'));
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    concluirAcao() {
      const acao = this.acoesAprovacao;
      acao.ids_acao = JSON.parse(acao.ids_acao);
      this.planejamentoAcaoMassivoResource.concluirAcaoMassiva(acao.ids_acao)
        .then(() => {
          this.buscarAcoes();
          this.$toast(this.$t('message.acoes_concluidas'));
        })
        .catch((err) => {
          const erros = JSON.parse(err.bodyText);
          const listaErros = erros.erros[0].split('.,');
          listaErros.forEach((e) => {
            this.$toast(e);
          });
        });
    },
    buscarStatusFluxo(acao, recarregando, cb) {
      if (acao.status === 'REPROVADO' || acao.status === 'EM_ANALISE' || recarregando) {
        const idAcao = acao.id;
        skipLoading();
        this.workflowAcaoResource.status({ idAcao })
          .then((response) => {
            acao.fluxo = response.data;
            if (cb) cb();
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    abrirJustificativa(acao) {
      this.justificativa = acao.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      this.$refs.modalJustificativa.open();
    },
    mostrarBotaoCancelarAcao(item) {
      return this.canEdit && (
        this.acaoEmCadastro(item)
        || (this.acaoAprovada(item) && item.cancela_aprovada)
        || (item.solicitanteFluxo && this.acaoAguardandoAprovacao(item))
      );
    },
  },
};
</script>
<style>
.PlanejamentoAcaoTabela__ColunaGrande {
  min-width: 150px;
}
.PlanejamentoAcaoTabela__ColunaMedia {
  min-width: 120px;
}
</style>
