<template>
  <div>
    <v-card class="PlanejamentoAcaoDados">

      <v-card-title class="mb-3">
        <div class="title">{{ $tc('title.planejamento_massivo', 1) }}</div>
      </v-card-title>
            <planejamento-acao-tabela
              :tipo-acao="tiposAcao"
              :ordenacao="parametrizacaoAcao[tiposAcao.id]"
              :label-campo-cliente="labelsCampoCliente[tiposAcao.id]"
              :filtros="filtrosAplicados"
              :agrupamento="filtrosAplicados.agrupamento"
              ref="dadosTabela"
              @refazer_busca="aplicaFiltros({})"
            />
    </v-card>

    <div class="text-xs-center mt-3" v-if="totalPagina > 1">
      <v-pagination
        v-model="pagina"
        :length="totalPagina"
        :total-visible="$vuetify.breakpoint.mdAndUp ? 15 : 7"
        @input="aplicaFiltros({})"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import { skipLoading } from '@/produto/common/functions/loading';
import PlanejamentoAcaoTabela from './PlanejamentoAcaoTabela';

export default {
  name: 'PlanejamentoAcaoDados',
  components: {
    PlanejamentoAcaoTabela,
  },
  data() {
    return {
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),

      tiposAcao: {},
      painel: null,
      pagina: 1,
      totalPagina: 1,
      quantidadePagina: 15,
      iniciandoPainel: true,

      filtrosAplicados: {},
      parametrizacaoAcao: {},
      labelsCampoCliente: {},
    };
  },
  methods: {
    tituloDoPainel() {
      return `${this.$t('label.tipo_acao')} :: ${this.$t('label.planejamento_massivo')}`;
    },
    alterarAgrupamento() {
      this.pagina = 1;
      this.$emit('PlanejamentoAcaoDados__AplicaFiltros');
    },
    aplicaFiltros(filtros) {
      this.filtrosAplicados = {
        ...filtros,
        numeroPagina: this.pagina,
        tamanhoPagina: this.quantidadePagina,
      };
      skipLoading();
      setTimeout(() => this.$refs.dadosTabela.buscarAcoes(this.filtrosAplicados), 500);
    },
  },
  mounted() {
  },
};
</script>
